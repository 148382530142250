import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Backdrop, Grid, Container } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SoftwareFeatures } from '.';
import { AbridgedFeatureList } from '../Features/AbridgedFeatureList';
// import { RmSpecialSoftwareFeatures } from '.';
const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'transparent',
	},
	paper: {
		borderRadius: '20px',
		maxWidth: '1280px',
		overflowX: 'hidden',
	},
	closeIcon: {
		color: theme.white,
		backgroundColor: 'transparent',
		fontSize: 46,
		marginBottom: '0',
		position: 'absolute',
		top: '0',
		right: '0',
		zIndex: '99',
		transition: 'all .3s ease-out',
		opacity: '0.8',
		'&:hover': {
			cursor: 'pointer',
			opacity: '1',
			transform: 'rotate(180deg) ',
		},
	},
}));

export const FeatureModal = ({
	open,
	setFeatureModalOpen,
	header,
	subheader,
	softwareFeatures,
	accentColor,
	lite,
}) => {
	const classes = useStyles();

	return (
		open && (
			<div>
				<Dialog
					aria-labelledby='spring-modal-title'
					aria-describedby='spring-modal-description'
					className={classes.modal}
					classes={{
						paper: classes.paper,
					}}
					maxWidth='md'
					open={open}
					onClose={(e) => setFeatureModalOpen(false)}
					BackdropComponent={Backdrop}>
					<CloseIcon
						onClick={(e) => setFeatureModalOpen(false)}
						className={classes.closeIcon}
					/>
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						item
						style={{ height: '90vh', width: '90vw' }}
						xs>
						<div className={classes.playerWrapper}>
							{lite ? (
								<AbridgedFeatureList
									softwareFeatures={softwareFeatures}
									header='PestPac Lite Header'
									subheader='Learn More About PestPacLite'
									// modal={modal}
									accentColor='#002D5C'
								/>
							) : (
								<SoftwareFeatures
									header={header}
									subheader={subheader}
									softwareFeatures={softwareFeatures}
									modal={true}
								/>
							)}
						</div>
					</Grid>
				</Dialog>
			</div>
		)
	);
};
